@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-Regular.ttf) format('ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-Bold.ttf) format('ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-Light.ttf) format('ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-Medium.ttf) format('ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-SemiBold.ttf) format('ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Omnes-Arabic';
  src: url(./Fonts/OmnesArabic-Thin.ttf) format('ttf');
  font-weight: 100;
}
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_Bold.ttf) format('ttf'); 
  font-weight: 700;
} 
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_Light.ttf) format('ttf'); 
  font-weight: 300;
}
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_Medium.ttf) format('ttf'); 
  font-weight: 500;
}
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_Regular.ttf) format('ttf'); 
  font-weight: 400;
}
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_SemiBold.ttf) format('ttf'); 
  font-weight: 600;
}
@font-face {
  font-family: 'Omnes';
  src: url(./Fonts/Omnes/Omnes_Thin.ttf) format('ttf'); 
  font-weight: 100;
}
body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1080px;
  height: 1920px;
  overflow: hidden;
} 
input , button{
  outline: none;
}
.ctn_form{
  margin-bottom: 10px;
}
.ctn_form h4{
  margin: 15px 8px 0 0 !important;
  width: 420px;
}
.clear{
  clear: both;
} 
body,button,input,.screen_background .MuiInputBase-input,p,span,h1,h2,h3,h4,h5,h6,.MuiTypography-h5,.screen_background h1,.MuiButton-root{
  font-family: 'Omnes';
}
@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: fit-content !important;
  }
}
p,h1,h2,h3,h4,h5,h6,span,div,button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: rgba(127, 127, 127, 0.4) !important;
} 
.popupListCalls .MuiDialog-paperWidthSm{
  max-width: 980px;
  max-height: calc(100% - 120px);
}
.popupListCalls .MuiDialog-paperWidthSm h6{
  text-align: center;
    width: 100%;
    display: unset;
    font-size: 22px;
    font-weight: 600; 
}
.popupListCalls .MuiDialog-paperWidthSm th{ 
  font-size: 15px;
}
.popupListCalls .MuiDialog-paperWidthSm td{ 
  font-size: 13px;
} 
.popupListCalls .MuiDialog-paperWidthSm tbody tr:hover{ 
  background-color: aliceblue;
}
.popupListCalls .MuiDialog-paperWidthSm .ctn_password svg{ 
  font-size: 20px;
    color: #fb7f08;
} 
.popupListCalls .MuiDialog-paperWidthSm .Mui-disabled svg{
  color: gray;
}
.popupResponsesCall .MuiFormHelperText-root.Mui-error ,.popupResponsesCallBtn .MuiFormHelperText-root.Mui-error{ 
  display: none;
}
.popupResponsesCall .MuiDialog-paperWidthSm , .popupResponsesCallBtn .MuiDialog-paperWidthSm {
  max-width: 850px;
  padding: 30px;
  border: 2px solid #fb7f08;
    border-radius: 10px;
} 
.MuiContainer-maxWidthXs {
  max-width: 570px !important;
}
.popupResponsesCallBtn .MuiDialogActions-root{
  justify-content: center;
}
.popupResponsesCallBtn .MuiDialog-paperWidthSm{
  max-width: 450px;
  padding: 20px;
}
.assignment-table .MuiTablePagination-root{
  display: none;
}
.popupResponsesCallBtn .MuiButton-label{
  font-size: 16px;
    letter-spacing: 2px;
}
.popupResponsesCallBtn .noReponse{
  border: 3px solid green;
}
.popupResponsesCallBtn .noReponse .MuiButton-label{
  color: green;
} 
.popupResponsesCallBtn .reponse{
  border: 3px solid red;
}
.popupResponsesCallBtn .reponse .MuiButton-label{
  color: red;
}
.popupResponsesCallBtn .MuiButton-label svg {
  font-size: 30px;
}
.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choisir fichier';
    color: #fb7f08;
    display: inline-block;
    border: 1px solid #fb7f08;
    border-radius: 3px;
    padding: 10px 14px;
    outline: none;
    white-space: nowrap; 
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-size: 12pt;
    transition: all 0.6s ease 0s;
}
.custom-file-input:hover::before {
  background-color: #fb7f08;
  color: #fff;
  text-shadow: 1px 1px #fb7f08;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.simple-keyboard {
  max-width: 850px;
} 
.pad .row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pad .key {
  border: 0px;
  border-radius: 50%;
  margin: 6px;
  line-height: 0;
  box-sizing: border-box;
  width: 96px;
  color: #11314F;
  padding: 25px;
  height: 96px;
  background: #EDF8FF;
  font-weight: 600;
  font-size: 28px;
}

.pad .key:hover { 
  border: none;
}

.pad .key-action { 
  background-color: transparent; 
  border: 2px solid #E4E7EC;
}
.pad .key-action svg{ 
  font-size: 20px;
}
 

.pad .input {
  padding: 0.5em;
  border: 0px;
  text-align: center;
}

.pad .status {
  font-size: 0.3em;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  color: dodgerblue;
}
/*---------Start Authentification----------*/
.screen_background{ 
height: 100vh;
padding: 349px 0 0 0;
position: relative;
} 
.screen_background .logo{
  position: absolute;
    width: 155px;
    height: 73px;
    left: 462px;
    top: 82px;
}
.screen_background .logo img{
  width: 100%;
    height: 100%;
}
.screen_background_delivery{  
  padding: 400px 0 0 0; 
  }
.screen_background h1{
  text-align: center;
  line-height: 40px;
  color: #11314F;
  margin: 0 auto 260px;
  font-weight: 300;
  font-size: 45px;
}
.screen_background h2{
  font-weight: 700;
  font-size: 57px;
  line-height: 64px;
  text-align: center;
  margin-bottom: 50px;
}
.screen_background h2 span:first-child{
  margin-right: 10px;
} 
.screen_background_otp{
  position: static;
}
.screen_background_otp h1{
  margin: 0 auto 106px;
  font-weight: 700;
    font-size: 45px;
    width: 707px;
    line-height: 52px;
    text-align: center;
} 
.screen_background .ctn_login{ 
}
.screen_background .ctn_login .login_numpad{
  margin: 0 auto 160px;
    padding: 60px 55px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 8px;
    width: 422px;
    height: 540px;
}
.screen_background .ctn_login .login_phone{
  margin-bottom: 200px;
} 
.screen_background .ctn_login .login_phone .input_icon{
  width: 422px;
  height: 83px;
  margin: 0 auto 112px;
  position: relative;
} 
.screen_background .ctn_login .login_phone .input_icon img{
  position: absolute;
  top: 25px;
  left: 20px;
  width: 32px;
  height: 32px;
  z-index: 1;
} 
.screen_background .ctn_login .login_phone .MuiInput-root{
  width: 100%;
} 
.screen_background .ctn_login .login_phone .MuiInput-underline::before ,.screen_background .ctn_login .login_phone .MuiInput-underline::after{
  display: none;
}  
.screen_background .ctn_login .login_phone input{
  box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid #F18800;
    box-shadow: 0px 0px 16px rgb(241 136 0 / 25%);
    border-radius: 12px;
    width: 422px;
    height: 83px;
    padding: 24px 20px 24px 64px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #98A2B3;
} 
.screen_background .ctn_login .login_phone .btn_submit{
  background: #F18800;
    margin: 0 auto;
    display: block;
    width: 422px;
    height: 81px;
    box-sizing: border-box;
    border-radius: 100px;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 32px;
}
.screen_background .ctn_login .login_phone .Mui-disabled{
  background: #98A2B3;
}
.screen_background .ctn_login .login_phone .btn_submit:hover{ 
background: #F18800; 
} 
.screen_background .ctn_login .login_phone .ctn_otp>div{
  margin: 0 auto 112px;
    width: 421px;
} 
.screen_background .ctn_login .login_phone .ctn_otp .otp{
  width: 57px !important;
  height: 83px;
  margin: 0 8px;
} 
.screen_background .ctn_login .login_phone .ctn_otp .otp:first-child{ 
  margin-left: 0;
} 
.screen_background .ctn_login .login_phone .ctn_otp .otp:last-child{ 
  margin-right: 0;
} 
.screen_background .ctn_login .login_phone .ctn_otp .otp input{
  box-shadow: none;
    width: 57px !important;
    height: 83px;
    background: #FFFFFF;
    border: 2px solid #E4E7EC;
    border-radius: 8px;
    padding: 17px 16px;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    color: #1D2939;
} 
.screen_background .ctn_login .login_phone .ctn_otp .otp span{
  display: none;
} 
/*---------End Authentification----------*/
.screen_background_home{
  padding: 0;
}
.img_home{
  height: 100%; 
width: 100%;
background-image: url('https://i.ibb.co/pZJNkHc/BORN-L3-ADAB.jpg');
}
.img_home img{
  height: 100%;
  width: 100%;
}
.screen_background_home .ctn_home{
  position: relative;
top: 1210px;
}
.screen_background_home .ctn_home h2{
  text-align: center;
    margin: 0 auto 36px;
    width: 449px;
    font-weight: 700;
    font-size: 57px;
    line-height: 64px;
    color: #11314F;
}
.screen_background_home .ctn_home p{
  text-align: center;
  margin-bottom: 72px;
  font-weight: 700;
  font-size: 32px;
  color: #11314F;
}
.screen_background_home .ctn_home .btn{
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  background: #11314F;
  border-radius: 100px;
  padding: 32px 100px;
  font-weight: 700;
  font-size: 40px;
  color: #FFFFFF;
  width: 543px;
  height: 104px;
  text-align: center;
}
.screen_background_home .img_home .btn span{ 
  text-transform: none;
position: relative;
top: -7px;
}
.screen_background_home_page .img_home button{
  opacity: 0;
}
.screen_background .sign_out{
  position: fixed;
    bottom: 80px;
    width: 60%;
    margin-left: 20%;
    height: 120px;
    font-size: 35px;
}
.ctn_btn_lang{
  text-align: center;
}
.ctn_btn_lang div{
  display: inline-block;
  width: 396px;
  height: 81px;
  box-sizing: border-box;
  background: #EDF8FF;
  border-radius: 100px;
  padding: 8px;
}
.ctn_btn_lang button{
  box-sizing: border-box;
    border: 0;
    border-radius: 100px;
    padding: 20px 24px;
    width: 184px;
    height: 65px;
    text-align: center;
    color: #11314F;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
}
.ctn_btn_lang .active{ 
background: #11314F;  
color: #fff; 
}
.ctn_btn_lang button:hover{
  border: 3px solid white;
}
.ctn_btn_lang .active:hover{  
  color: white;  
  background: #11314F; 
  } 
.ctn_btn_back{
  left: 82px;
  top: 82px;
  position: absolute;
}
.ctn_btn_back button{
  box-sizing: border-box;
  font-weight: 500;
  color: #11314F;
  width: 205px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #98A2B3;
  border-radius: 100px;
  font-size: 22px;
  line-height: 28px;
}
.ctn_btn_back button svg{
  margin-right: 7px;
}
.ctn_btn_back button:hover{
  background: #F2F4F7;
  color: #11314F; 
}
.ctn_btn_back .btn_back_icon{
  width: 76px;
    height: 60px;
}
.ctn_methods{
  text-align: center;
}
.ctn_methods .ctn{
  overflow: hidden;
display: inline-block;
}
.ctn_methods .ctn div{
  float: left;
    margin: 0 36px;
    box-sizing: border-box;
    text-align: center;
    width: 376px;
    height: 500px;
    padding: 44px 27px 56px;
    background: #EDF8FF;
    border: 1px solid #98A2B3;
    border-radius: 5px;
}
.ctn_methods .ctn div img{
  margin-bottom: 50px;
    width: 194px;
    height: 236px;
}
.ctn_methods .ctn div h3{
  margin: 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  color: #11314F;
}
.ctn_methods .ctn div h4{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #11314F;
  margin: 16px auto 0;
}
/*===================start Catalogue======================*/
.ctn_catalogue{
  overflow: hidden;
}
.ctn_catalogue .ctn_top{
  width: 1080px; 
    overflow: hidden;
    height: 166px;
    border-bottom: 1px solid #E4E7EC;
    position: relative;
}
.ctn_catalogue .ctn_top .btn_promotion{ 
    position: absolute;
    height: 137px;
    width: 136px;
    left: 450px;
    top: 10px;     
}
.ctn_catalogue .ctn_top .btn_promotion .ctn_btn_promotion{
  position: relative;
}
.ctn_catalogue .ctn_top .btn_promotion img{
  width: 100%;
  height: 100%;
}
.ctn_catalogue .ctn_top .btn_promotion div{
  width: 100%;
  height: 100%;
}
.ctn_catalogue .ctn_top .btn_promotion .ctn_flash_promotion{
  width: 45.39px;
  height: 60.83px;
  position: absolute;
  top: 0;
  right: 0;
}
.ctn_catalogue .ctn_top .logo{
  margin: 39px 0 0 36px;
  box-sizing: border-box;
  float: left;
  width: 126px; 
}
.ctn_catalogue .ctn_top .logo img{
  width: 100%;
height: 100%;
}
.ctn_catalogue .ctn_top .info{
  float: right;
    margin: 49px 36px 0 0;
} 
.ctn_catalogue .ctn_top .info div{
  text-align: center;
}  
.ctn_catalogue .ctn_top .info div p:first-child{
  font-weight: 600;
  font-size: 28px;
  margin: 0;
  color: #1D2939;
} 
.ctn_catalogue .ctn_top .info div p:last-child{
  font-weight: 600;
    font-size: 16px;
    margin: 8px 0 0 0;
    color: #667085;
} 
.ctn_catalogue .ctn_left{
  float: left;
    padding: 86px 0;
    height: calc(100vh - 400px);
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border-right: 1px solid #E4E7EC;
    width: 209px;
}
.ctn_catalogue .ctn_left::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
} 
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family{
  overflow: hidden;
    padding: 0;
    cursor: pointer;
    margin-top: 32px;
}
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family:first-child{
  margin: 0;
}
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family div:first-child{
  float: left;
  height: 129px;
    margin-right: 16px;
    width: 12px;
}
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family_selected div:first-child{ 
background: #F18800; 
} 
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family div:last-child{
  float: left;
    width: 130px;
}
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family img{
  display: block;
    margin: 0 auto;
    width: 89px;
    height: 89px;
}
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family p{
  color: #1D2939;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    line-height: 32px;
    text-align: center;
} 
.ctn_catalogue .ctn_left .ctn_nav_families .family_list .family_selected p{ 
  font-weight: 700;
  }
.ctn_catalogue .ctn_right{
  float: left;
width: 840px;
}
.ctn_catalogue .ctn_right .ctn_nav_brands{
  padding: 8px 0 0 28px;
  border-bottom: 1px solid #E4E7EC;
}
.ctn_catalogue .ctn_right .ctn_nav_brands h4{
  font-weight: 600;
    font-size: 32px;
    margin: 12px 0 16px 0;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list{
  width: 812px;
height: 134px;
overflow-x: scroll;
  display: Block;
  white-space: nowrap;  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */ 
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list::-webkit-scrollbar {
  display: none;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list .brand{
  display: inline-block;
margin-right: 40px;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list .brand img{
  width: 84px;
height: 84px;
margin-bottom: 8px;
border-radius: 50%;
overflow: hidden;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list .brand span{
  font-weight: 600;
font-size: 16px;
display: block;
text-align: center;
margin-bottom: 10px;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list .brand div{
  width: 84px;
height: 6px;
background: #fff;
}
.ctn_catalogue .ctn_right .ctn_nav_brands .brand_list .brand_selected div{  
  background: #F18800;
}
.ctn_catalogue .ctn_right .ctn_nav_articles{ 
height: calc(100vh - 900px);
-ms-overflow-style: none; /* for Internet Explorer, Edge */
scrollbar-width: none; /* for Firefox */
overflow-y: scroll; 
}
.ctn_catalogue .ctn_right .ctn_nav_articles::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.ctn_catalogue .ctn_right .ctn_nav_articles .ctn_bloc{
  padding: 0 0 880px 0;
}
.ctn_catalogue .ctn_right .ctn_nav_articles .ctn_bloc .bloc{
  padding: 32px 30px 0 30px;
}
.ctn_catalogue .ctn_right .ctn_nav_articles .ctn_bloc .bloc h4{
  font-weight: 700;
font-size: 28px;
color: #1D2939;
margin-bottom: 24px;
}
.articles_listes_catalog{
  overflow: hidden;
}
.articles_listes_catalog .article{
  width: 222px;
  height: 360px;
  box-sizing: border-box;
  padding: 12px;
  float: left;
  margin: 0 28px 28px 0;
  position: relative;
  border: 1px solid #98A2B3;
  border-radius: 2px;
  overflow: hidden;
}
.articles_listes_catalog .article:nth-child(3n+3){ 
  margin-right: 0;
}
.articles_listes_catalog .article .ctn_img{
  height: 198px;
margin-bottom: 8px;
text-align: center;
}
.articles_listes_catalog .article .ctn_img img{ 
height: 100%;
}
.articles_listes_catalog .article .ctn_price{
  overflow: hidden;
margin-bottom: 4px;
}
.articles_listes_catalog .article .ctn_price span{
  display: block;
letter-spacing: 0.5px;
}
.articles_listes_catalog .article .ctn_price span:first-child{
  float: left;
font-weight: 500;
font-size: 15px;
color: #1D2939;
}
.articles_listes_catalog .article .ctn_price span:last-child{
  float: right;
    text-decoration-line: line-through;
    color: #667085;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
}
.articles_listes_catalog .article .ctn_desc{
  margin-bottom: 2px;
    min-height: 48px;
}
.articles_listes_catalog .article .ctn_desc h5{
  margin: 0;
  color: #1D2939;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
} 
.articles_listes_catalog .article .ctn_info p{
  margin: 0;
    letter-spacing: 0.5px;
    color: #667085;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}
.articles_listes_catalog .article .promo{
  position: absolute;
    top: 20px;
    left: 0;
    box-sizing: border-box;
    text-align: center;
    width: 55px;
    height: 32px;
    background: #E03636;
    border-radius: 0px 2px 2px 0px;
    padding: 8px 12px;
}
.articles_listes_catalog .article .promo span{
  position: relative;
    top: -4px;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
}
.articles_listes_catalog .article .ctn_bottom_info{
  overflow: hidden;
  border: 0.5px solid #98A2B3;
  border-radius: 4px;
  background: #FFFFFF;
  height: 54px;
  margin-top: 8px;
}
.articles_listes_catalog .article .ctn_bottom_info>div{
  width: 50%;
    float: left;
    padding: 4px;
    box-sizing: border-box;
}
.articles_listes_catalog .article .ctn_bottom_info div p{
  margin: 0;
  padding: 0;
  line-height: normal;
}
.articles_listes_catalog .article .ctn_bottom_info .left{
  background-color: #E03636;
    color: white;
}
.articles_listes_catalog .article .ctn_bottom_info .left p{
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}
.articles_listes_catalog .article .ctn_bottom_info .left p:first-child{
  font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.5px;
}
.articles_listes_catalog .article .ctn_bottom_info .left p:last-child{
  font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.5px;
}
.articles_listes_catalog .article .ctn_bottom_info .right{
  background-color: #fff;
    color: #1D2939;
    text-align: right;  
    height: 100%;
    display: table;
}
.articles_listes_catalog .article .ctn_bottom_info .left{ 
    height: 100%;
    display: table;
}
.articles_listes_catalog .article .ctn_bottom_info .right div,.articles_listes_catalog .article .ctn_bottom_info .left div{ 
  display: table-cell;
    vertical-align: middle;
}
.articles_listes_catalog .article .ctn_bottom_info .right p{
  font-weight: 500;
  font-size: 16px;
}
.articles_listes_catalog .article .ctn_bottom_info .right p span{
  color: white;
}
.articles_listes_catalog .article .ctn_bottom_info .right p:first-child{
  font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-decoration: line-through;
}
.articles_listes_catalog .article .ctn_bottom_info .right .no_line_through{ 
    opacity: 0;
}
.articles_listes_catalog .article .ctn_bottom_info .right p:last-child{
  font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.5px;
}
.articles_listes_catalog .article .ctn_bottom_info_without_palier .right{
  width: 100%;
    text-align: center;
}
.articles_listes_catalog .article .added_cart{
  width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: #F18800;
    text-align: center;
}
.articles_listes_catalog .article .added_cart div{
  height: 100%;
}
.articles_listes_catalog .article .added_cart div img{
  width: 21px;
height: 21px;
position: relative;
top: 11px;
}  
.articles_listes_catalog .article .qte{ 
  width: 45px;
height: 162px;
position: absolute;
top: 12px;
right: 12px;
}
.articles_listes_catalog .article .qte .ctn{
  width: 100%;
height: 100%;
}
.articles_listes_catalog .article .qte .ctn div{
  width: 44px;
height: 44px;
border-radius: 50%;
position: absolute; 
background-color: #F18800;
text-align: center;
}
.articles_listes_catalog .article .qte .ctn div img{
  width: 21px; 
position: relative;
}
.articles_listes_catalog .article .qte .ctn .plus{
  top: 0px;
  right: 0px; 
}
.articles_listes_catalog .article .qte .ctn .plus img{ 
height: 21px; 
top: 11px;
}
.articles_listes_catalog .article .qte .ctn input{
  width: 100%;
height: 100%;
border: none;
background: #F9FAFB;
box-shadow: 0px 4px 10px rgba(29, 41, 57, 0.16);
border-radius: 66px;
box-sizing: border-box;
padding: 60px 0;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #1D2939;
} 
.articles_listes_catalog .article .qte .ctn .minus{
  top: 118px;
right: 0px;
}
.articles_listes_catalog .article .qte .ctn .minus img{ 
height: 3px; 
top: 6px;
}
.articles_listes_catalog .article .repture{
  position: absolute;
    top: 0;
    left: 0;
    background: #3d546a;
    border-radius: 1px 1px 0px 0px;
    width: 100%;
    height: 40px;
}
.articles_listes_catalog .article .repture div{
  font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}
.articles_listes_catalog .article .repture div span{
  position: relative;
    top: 5px;
}
.ctn_panier{
  position: absolute;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    width: 870px;
    height: 260px;
    background: #FFFFFF;
    box-shadow: 0px -5px 5px rgb(0 0 0 / 16%);
    padding: 50px 50px 44px 55px;
}
.ctn_panier .total_price{ 
    width: 413px;
    margin-left: 345px;
}
.ctn_panier .total_price span{
  font-weight: 700;
font-size: 32px;
display: block;
float: left;
}
.ctn_panier .total_price span:first-child{ 
}
.ctn_panier .total_price>div:first-child{
  float: left;
    position: relative;
}
.ctn_panier .total_price>div:first-child img{
  width: 48px;
  height: 48px;
}
.ctn_panier .total_price>div:first-child div{
  border: 2px solid #FFFFFF;
    border-radius: 100px;
    background: #EB6C43;
    width: 35px;
    height: 24px;
    text-align: center;
    position: absolute;
    top: -10px;
    right: -20px;
}
.ctn_panier .total_price>div:first-child div span{
  font-weight: 600;
    font-size: 16px;
    color: #fff;
    display: block;
    margin: 0;
    float: none;
    position: relative;
    top: 2px;
} 
.ctn_panier .total_price>div:last-child span{
  font-weight: 700;
  font-size: 32px;
    line-height: 28px;
    color: #11314F;
}
.restart_order{
  box-sizing: border-box;
    text-align: center;
    float: left;
    width: 327px;
    height: 81px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 100px;
    margin: 0 20px 0 0;
}
.ctn_panier .total_price span:last-child{
  float: right;
}
.ctn_panier .ctn_btn{
  overflow: hidden;
margin-top: 38px;
} 
.ctn_panier .ctn_btn .add_order{
  width: 413px;
  height: 81px;
  background: #F18800;
  border-radius: 100px;
}
.ctn_panier .ctn_btn span{
  margin-top: 5px;
    display: inline-block;
}
/*---------start popup single article----------*/ 
.popup_single_article .MuiDialog-paper{
  max-width: none;
  background: #FFFFFF;
  width: 647px;
  height: auto;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 0 100px 0;
}
.popup_single_article .single_article{ 
padding: 0 32px;
}
.popup_single_article .single_article .ctn_img{
  text-align: center;
    height: 351px;
} 
.popup_single_article .single_article .ctn_img img{
  height: 351px;
  width: 351px; 
}
.popup_single_article .single_article .ctn_inf{
  overflow: hidden;
}
.popup_single_article .single_article .ctn_inf .ctn_desc{
  width: 380px;
float: left;
}
.popup_single_article .single_article .ctn_inf .ctn_desc h5{
  color: #1D2939;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
}
.popup_single_article .single_article .ctn_inf .ctn_price{
  width: 150px;
    float: right;
}
.popup_single_article .single_article .ctn_inf .ctn_price span{
  display: block;
    text-align: right;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
}
.popup_single_article .single_article .ctn_inf .ctn_price span:first-child{
  margin-bottom: 4px;
}
.popup_single_article .single_article .ctn_inf .ctn_price span:last-child{
  font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: line-through;
} 
.popup_single_article .single_article .ctn_info p{
  font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1D2939;
}
.popup_single_article .single_article .ctn_qte{
  margin-top: 32px;
overflow: hidden;
}
.qte_horizontal{
  position: relative;
    float: left;
    width: 298px;
    height: 64px;
}
.qte_horizontal .ctn{
  width: 100%;
height: 100%;
}
.qte_horizontal .ctn div{
  border-radius: 50%;
    position: absolute;
    background-color: #F18800;
    text-align: center;
    width: 56px;
    height: 56px;
}
.qte_horizontal .ctn div img{
  width: 28px; 
position: relative;
} 
.qte_horizontal .ctn .plus{
  top: 4px;
right: 4px;
}
.qte_horizontal .ctn .plus img{ 
  height: 28px;
  top: 13px;
}
.qte_horizontal .ctn input{
  width: 100%;
    height: 100%;
    background: #F9FAFB;
    box-sizing: border-box;
    padding: 0 60px;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #11314F;
    border: 1px solid #E4E7EC;
    border-radius: 100px;
}
.qte_horizontal .ctn .minus{
  top: 4px;
  left: 4px;
}
.qte_horizontal .ctn .minus img{ 
height: 6px; 
top: 12px;
}
.popup_single_article .single_article .ctn_qte .total_price{
  float: right;
    position: relative;
    top: 18px;
}
.popup_single_article .single_article .ctn_qte .total_price span{
  font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #1D2939;
}
.popup_single_article .single_article .ctn_btn{
  margin-top: 32px;
overflow: hidden;
} 
.btn_order span{
  font-weight: 600;
font-size: 26px;
line-height: 57px;
}
.delete_order{
  width: 232px;
height: 57px;
float: left;
margin-right: 20px;
background: #F2F4F7;
border-radius: 100px;
text-align: center;
color: #11314F;
} 
.add_order{
  text-align: center;
    float: left;
    color: white;
    width: 294px;
    height: 64px;
    background: #F18800;
    border-radius: 100px;
} 
.popup_single_article .promo{
  position: absolute;
    box-sizing: border-box;
    text-align: center;
    padding: 8px 12px;
    top: 28px;
    left: -3px;
    background: #E03636;
    border-radius: 5px;
    width: 84px;
    height: 48px;
}
.popup_single_article .promo span{
  position: relative;
    font-weight: 600;
    font-size: 16px;
    color: white;
    line-height: 30px;
}
.popup_single_article .close{
  width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 36px;
    right: 36px;
    text-align: center;
}
.popup_single_article .close div{
  height: 100%;
}
.popup_single_article .close div img{
  width: 32px;
  height: 32px;
  position: relative;
}  
.popup_single_article .code{
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.popup_single_article .code p{
  font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #1D2939;
}
/*================= slider fiche produit===================================*/
.carousel .control-dots {  
}
.carousel.carousel-slider .control-arrow { 
  display: none;
}
.carousel .carousel-status { 
  display: none;
}
.carousel .thumbs { 
  display: none;
}
.carousel .control-dots .dot {
  transition: none;
  filter: none;
  background: #E4E7EC;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  opacity: 1;
  box-shadow: none;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: none;
  background: #EB6C43;
}
.carousel .control-dots { 
  bottom: 15px; 
}
.carousel .thumbs-wrapper {
  display: none;
}
/*====================================================*/
/*---------start panier----------*/
.page_panier .dialog_msg{
  left: 400px;
  top: 120px;
}
.page_panier .panier_top{
  background: #FFFFFF;
    border-bottom: 1px solid #E4E7EC;
    height: 228px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 50px 36px;
    margin-bottom: 60px;
}
.page_panier .panier_top .ctn_left{
  float: left;
    width: 108.94px;
    height: 124px;
    margin-right: 60px;
}
.page_panier .panier_top .ctn_left img{
  width: 100%;
    height: 100%;
}
.page_panier .panier_top .ctn_center{
  float: left;
}
.page_panier .panier_top .ctn_center p:first-child{
  font-weight: 700;
    font-size: 36px;
    margin: 0 0 10px 0;
    color: #1D2939;
} 
.page_panier .panier_top .ctn_center p:last-child{
  font-weight: 600;
font-size: 24px;
margin: 0;
color: #667085;
}
.page_panier .panier_top .ctn_center .ref{
  font-weight: 500;
font-size: 22px;
margin: 0 0 10px 0;
color: #1D2939;
}
.page_panier .panier_top .ctn_center .ref span:first-child{
  margin-right: 5px;
}
.page_panier .panier_top .ctn_right{
  float: right;
} 
.page_panier .panier_top .ctn_right div p{
  text-align: center;
}
.page_panier .panier_top .ctn_right div p:first-child{
  font-weight: 600;
font-size: 28px;
margin: 0 0 10px 0;
color: #1D2939;
}
.page_panier .panier_top .ctn_right div p:last-child{
  font-weight: 600;
font-size: 16px;
color: #667085;
margin: 0;
}
.page_panier .ctn_orderlines{
  padding: 0 60px 40px 48px;
height: 1235px;
-ms-overflow-style: none; /* for Internet Explorer, Edge */
scrollbar-width: none; /* for Firefox */
overflow-y: scroll; 
}
.page_panier .ctn_orderlines::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.page_panier .ctn_orderlines .orderline{
  overflow: hidden;
  margin-bottom: 40px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}
.page_panier .ctn_orderlines .orderline .errQte{
  float: none;
    width: 172px;
    position: absolute;
    bottom: 8px;
    right: 269px;
    border-radius: 3px;
    height: 28px;
    text-align: center;
}
.page_panier .ctn_orderlines .orderline .errQte span{
  font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #B42318;
    display: block;
    background: #FFE2E0;
}
.page_panier .ctn_orderlines .orderline>div{
  float: left;
}
.page_panier .ctn_orderlines .orderline .promo{
  float: none;
  position: absolute;
  top: 12px;
  left: 0px;
  width: 44px;
  height: 24px;
  background: #FFC700;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #11314F;
  text-align: center;
  line-height: 24px;
}
.page_panier .ctn_orderlines .orderline .ctn_img{
  overflow: hidden;
    width: 132px;
    height: 132px;
    margin-right: 16px;
    background: #FFFFFF;
    border-radius: 5px;
}
.page_panier .ctn_orderlines .orderline .ctn_img img{
  width: 100%;
height: 100%;
}
.page_panier .ctn_orderlines .orderline .ctn_inf{
  width: 331px;
padding: 16px 0 0 0;
margin-right: 40px;
} 
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_desc h5{
  margin: 0 0 8px 0;
  font-weight: 400;
  font-size: 20px;
  color: #1D2939;
  letter-spacing: 0.1px;
} 
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_info p{
  margin: 0 0 12px 0;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #667085;
}  
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_price span:first-child{
  font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #1D2939;
    margin-right: 16px;
}
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_price span:last-child{
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.5px;
  text-decoration-line: line-through;
  color: #667085;
} 
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_gratuite p{
  font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #039855;
    margin: 0;
}
.page_panier .ctn_orderlines .orderline .qte_horizontal{
  margin: 43px 50px 0 0;
    padding: 4px;
    width: 172px;
    height: 48px;
    border: 1px solid #98A2B3;
    border-radius: 100px;
    box-sizing: border-box;
} 
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn div{
  background-color: #11314F;
  width: 40px;
    height: 40px;
    top: 2px;
}
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn div img{
  width: 20px;
}
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn .plus{
  right: 2px;
}
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn .plus img{
  height: 20px;
    top: 10px;
} 
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn .minus{
  
  left: 2px;
}
.page_panier .ctn_orderlines .orderline .qte_horizontal .ctn .minus img{
  height: 6px;
    top: 10px;
} 
.page_panier .ctn_orderlines .orderline .total_price span{
  font-weight: 600;
font-size: 20px;
line-height: 130px;
}
.page_panier .ctn_orderlines .orderline .btn_delete{
  width: 32px;
  height: 32px;
  padding: 7px;
  box-sizing: border-box;
  position: absolute;
  top: 12px;
  right: 12px;
}
.page_panier .ctn_orderlines .orderline .btn_delete img{
  width: 18px;
    height: 18px;
}
.page_panier .ctn_panier{
  padding: 26px 52px 44px 70px;
    width: 1080px;
    border-radius: 0px;
    position: relative;
    height: 333px;
    background: #FFFFFF;
    box-shadow: 0px -5px 20px rgb(0 0 0 / 16%);
} 
.page_panier .ctn_panier .info_seuil{
  border-radius: 5px;
  position: absolute;
  width: 360px;
  height: 72px;
  padding: 8px;
  box-sizing: border-box;
  top: 60px;
  left: 75px;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #98a2b3;
  text-align: center;
}
.page_panier .ctn_panier .info_seuil img {
  height: 40px;
  width: 40px;
  margin: 0 10px;
  position: relative;
  top: 12px;
}
.page_panier .ctn_panier .panier_info {
  padding: 0 0 0 440px;
  overflow: hidden;
 }
.page_panier .ctn_panier .panier_info span{
  line-height: 24px;
    letter-spacing: 0.1px;
    color: #667085;
    font-weight: 400;
    font-size: 16px;
 }
.page_panier .ctn_panier .panier_info span:first-child{
  float: left;
}
.page_panier .ctn_panier .panier_info span:last-child{
  float: right;
}  
.page_panier .ctn_panier .total_price{
  overflow: hidden;
  margin: 20px 0 0 440px;
  width: auto;
} 
.page_panier .ctn_panier .total_price span:first-child{
  margin: 0;
    float: left;
    font-weight: 700;
    font-size: 32px;
}
.page_panier .restart_order{
  width: 232px;
  background: #F9FAFB;
}
.page_panier .ctn_panier .total_price span:last-child{
  float: right;
} 
.page_panier .ctn_panier .ctn_btn .more_order{
  float: left;
    text-align: center;
    margin-right: 32px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 100px;
    width: 401px;
    height: 81px;
}
.page_panier .ctn_panier .ctn_btn .add_order{
  background: #F18800;
    border-radius: 100px;
    width: 521px;
    height: 81px;
}
.page_panier .ctn_panier .ctn_btn span{
  font-weight: 600;
    font-size: 26px;
    line-height: 70px;
    text-align: center;
    color: #11314F;
}
.page_panier .ctn_panier .ctn_btn .add_order span{ 
    color: #fff;
} 
/*==============receipt=================*/
table {
  border-collapse: collapse;
}
.receipt{
  width: 980px;
margin: 0 auto;
border: 1px solid #f2f2f2;
padding: 15px;
box-sizing: border-box;
}
.receipt h3{
  text-align: center;
margin: 0px;
font-size: 100px;
}
.receipt h6{
  text-align: center;
font-size: 40px;
margin: 5px 0px;
}
.receipt h5{
  text-align: center;
font-size: 32px;
margin: 10px 0px 15px 0;
}
.receipt .sep_etoil{
  text-align: center;
}
.receipt .sep_etoil p{
  display: inline-block; 
}
.receipt .receipt_info{
  font-size: 40px;
padding: 0px 50px;
margin-bottom: 5px;
}
.receipt .receipt_info b{
  display: inline-block;
width: 380px;
}
.receipt .table_article{
  width: 850px;
margin: 20px auto; 
} 
.receipt .table_article thead tr{
  border-bottom: 2px dashed black;
}
.receipt .table_article thead tr th{
  text-align: center;
  font-size: 45px;
}
.receipt .table_article thead tr th:first-child{
  text-align: left;
}
.receipt .table_article tbody tr{
  border-top: 20px solid;
  border-top-color: currentcolor;
border-color: transparent;
}
.receipt .table_article tbody tr td{
  text-align: center;
  font-size: 40px; 
}
.receipt .table_article tbody tr td:first-child{
  text-align: left;
width: 460px;
box-sizing: border-box;
padding: 0 60px 0 0;
}
.receipt .nbr_articles{
  font-size: 40px;
padding: 0px 50px;
margin-bottom: 5px;
font-weight: bold;
}
.receipt .order_info tbody tr td{
  width: 210px;
font-size: 35px;
}
.receipt .order_info tbody tr td:first-child{
  width: 340px;
}
.receipt .order_info tbody tr td:last-child{
  text-align: right;
}   
.screen_background_responsable h1{
  margin: 0 auto 174px;
}
.screen_background_channel h1{
  margin: 0 auto 117px;
}
.screen_background_channel .btn_submit{
  margin: 160px auto 0;
    color: #FFFFFF;
    background: #F18800;
    font-weight: 600;
    font-size: 20px;
    border-radius: 100px;
    width: 422px;
    height: 81px;
    box-sizing: border-box;
    display: block;
}
.screen_background_channel .MuiButton-root.Mui-disabled{
  background: #98A2B3;
  color: white;
}
.ctn_channels .channel{
  width: 750px;
    height: 116px;
    padding: 40px 32px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0 auto 24px;
    position: relative;
}
.ctn_channels .channel p{
  font-weight: 500;
    font-size: 30px;
    margin: 0;
    color: #11314F;
    text-align: center;
}
.ctn_channels .active p{
  font-weight: 700;
}
.ctn_channels .channel img{
  position: absolute;
    top: 40px;
    right: 40px;
    display: none;
}
.ctn_channels .active{
  border: 3px solid #F18800;
}
.ctn_channels .active img{
  display: block;
}
.ctn_cgu{
  margin: 50px auto 0;
    overflow: hidden;
    width: 750px;
}
.ctn_cgu div:first-child{
  float: left;
    width: 44px;
    height: 44px;
    margin-right: 15px;
} 
.ctn_cgu div:last-child{
  float: left;
  width: 678px;
}
.ctn_cgu div:last-child p{
  margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #1D2939;
}
.ctn_cgu div:last-child p a{
  color: #F18800;
}
.ctn_cgu .MuiCheckbox-colorSecondary.Mui-checked {
  color: #F18800;
}
.MuiDialog-paperWidthSm { 
  width: 647px !important;
  height: 349px;
}
.MuiDialogContent-root { 
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin: 20px auto 70px;
  flex: none !important;
}
.MuiDialogActions-root button:first-child{
  width: 249px;
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 100px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #11314F;
}
.MuiDialogActions-root button:last-child{
  background: #F18800;
  border-radius: 100px;
  width: 294px;
  height: 64px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
}
.MuiDialogActions-root { 
  display: block !important;
  padding: 8px 24px !important;
}
.confirmed_order .ctn_img{
  width: 384px;
    height: 384px;
    margin: 277px auto 52px;
    border: 2px solid gray;
    border-radius: 50%;
}
.confirmed_order h3{
  margin: 15px 0;
    text-align: center;
    font-weight: 700;
    margin-top: 341px;
    letter-spacing: -0.25px;
    color: #11314F;
    font-size: 57px;
}
.confirmed_order h4{
  margin: 0;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #1D2939;
}
.confirmed_order h5{
  margin: 16px 0 0 0;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #11314F;
}
.confirmed_order h6{
  font-weight: 500;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: #11314F;
    margin: 242px 0 36px;
}
.confirmed_order .ctn_img img{
  width: 100%;
  height: 100%;
} 
.confirmed_order .ctn_btn{
  overflow: hidden; 
  padding: 0 175px;
}
.confirmed_order .ctn_btn div:first-child{
    width: 301px;
    height: 81px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 100px;
    text-align: center;
    font-size: 20px;
    color: #11314F;
    font-weight: 600;
    line-height: 81px;
    float: left;
    margin-right: 24px;
}
.confirmed_order .ctn_btn div:last-child{
  width: 399px;
    height: 81px;
    background: #F18800;
    border-radius: 100px;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 81px;
    float: left;
}
.dialog_msg{
  position: absolute;
  width: 433px;
  height: 0;
  opacity: 0;
  left: 324px;
  top: 39px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 8px;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
  overflow: hidden;
  z-index: 2;
}
.ctn_catalogue_ar .dialog_msg ,.page_panier_ar .dialog_msg{
  direction: rtl;
}
.ctn_catalogue_ar .dialog_msg div img ,.page_panier_ar .dialog_msg div img{ 
  margin-right: 0;
  margin-left: 16px;
}
.dialog_msg div{
  height: 100%;
  color: white;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
  display: none; 
}
.dialog_msg div img{
  width: 32px;
    height: 32px;
    display: inline-block; 
    margin-right: 16px;
}
.dialog_msg div span{
  font-weight: 500;
    font-size: 20px;
    display: inline-block; 
} 
.dialog_msg_bloc{ 
  height: 56px; 
  opacity: 1; 
}
.dialog_msg .msg_bloc{
  display: block;  
} 
.dialog_msg .info_msg{ 
  background: #1D2939;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
} 
.dialog_msg .info_msg span ,.dialog_msg .msg_qte span{
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  position: relative;
  top: -10px;
}
.dialog_msg .msg_qte{
  background: #B42318;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}
.popup_slots .MuiDialogContent-root{
  padding: 0;
  margin: 0;
}
.popup_slots .MuiDialog-paperWidthSm { 
  width: 647px !important;
  height: auto;
  max-width: initial;
  background: #FFFFFF;
  border-radius: 5px;
}
.popup_slots .MuiDialogTitle-root{
  font-weight: 500;
    font-size: 28px;
    color: #1D2939;
    width: 488px;
    text-align: center;
    margin: 85px auto 60px;
    padding: 0;
}
.popup_slots .ctn_slots div{
  width: 583px;
    margin: 0 auto 24px;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 5px;
    overflow: hidden;
    height: 116px;
    box-sizing: border-box;
    padding: 40px 32px;
}
.popup_slots .ctn_slots div p{
  float: left;
  margin: 0;
  color: #11314F;
  font-weight: 600;
  font-size: 24px;
}
.popup_slots .ctn_slots div img{
  float: right;
  display: none;
}
.popup_slots .ctn_slots .active{
  border: 3px solid #F18800;
}
.popup_slots .ctn_slots .active img{
  display: block;
}
.popup_slots .MuiDialogActions-root{
  margin: 20px auto;
}
.popup_slots .MuiDialogActions-root button{
  width: 375px;
  height: 81px;
}
.popup_slots .MuiDialogActions-root .Mui-disabled{
  background: #98A2B3;
}
.MuiDialogContent-root { 
  font-size: 27px; 
} 
.screen_background_phone_customer{
  padding: 279px 0 0 0;
}
.screen_background_phone_customer .ctn_btn_lang { 
  margin-bottom: 203px;
}
.screen_background_phone_customer h1 { 
  margin: 0 auto 65px; 
}
/*====================version arabe=========================*/
.ctn_cgu_ar div:first-child {
  float: right; 
  margin-left: 15px;
  margin-right: 0px;
}
.ctn_cgu_ar div:last-child {
  float: right; 
  text-align: right;
}
.screen_background .title_ar{
  
}
.screen_background .title_ar p{
  overflow: hidden;
    margin: 0;
    display: inline-block;
}
.screen_background .title_ar span{
    float: right;
    margin: 0 5px;
}
.articles_listes_catalog_ar .article .ctn_price span:first-child {
  float: right; 
}
.articles_listes_catalog_ar .article .ctn_price span:last-child {
  float: left; 
}
.articles_listes_catalog_ar .article .ctn_desc h5 {
  text-align: right;
}
.articles_listes_catalog_ar .article .ctn_info p {
  text-align: right;
  direction: rtl;
}
.articles_listes_catalog_ar .article .ctn_price span {
  direction: rtl;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .ctn_price {
  direction: rtl;
  text-align: right;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .ctn_price span:first-child {
  margin-left: 16px;
  margin-right: 0;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .ctn_desc {
  text-align: right;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .ctn_info {
  text-align: right;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf {
  width: 290px;
  margin-right: 70px;
}
.page_panier_ar .ctn_orderlines .orderline .total_price {
  direction: rtl;
}
.ctn_catalogue_ar .ctn_panier .total_price .total { 
  direction: rtl;
}
.confirmed_order_ar h6 {
  font-weight: 500;
  font-size: 35px; 
}
.page_panier_ar .ctn_panier .panier_info { 
  direction: rtl;
}
.page_panier_ar .ctn_panier .total_price span:last-child { 
  direction: rtl;
}
.page_panier_ar .ctn_panier .panier_info span:first-child {
  float: right;
}
.page_panier_ar .ctn_panier .panier_info span:last-child {
  float: left;
}
.page_panier_ar .ctn_panier .total_price span:first-child {
  float: right; 
}
.page_panier_ar .ctn_panier .total_price span:last-child {
  float: left;
}
.page_panier .ctn_panier .ctn_btn .btn_order_disabled {
  background: #98A2B3; 
}
.page_panier_ar .ctn_panier .info_seuil { 
  direction: rtl;
} 
.page_panier .ctn_orderlines .orderline .ctn_inf .ctn_gratuite p{
  direction: ltr;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .ctn_gratuite p{
  direction: rtl;
}
.popup_single_article_ar .single_article .ctn_inf { 
  direction: rtl;
}
.popup_single_article_ar .single_article .ctn_inf .ctn_desc { 
  float: right;
}
.popup_single_article_ar .single_article .ctn_inf .ctn_price { 
  float: left;
}
.popup_single_article_ar .single_article .ctn_info {
  direction: rtl;
}  
.popup_single_article_ar .single_article .ctn_qte .total_price { 
  direction: rtl;
  position: relative;
  top: 10px;
}
.popup_single_article_ar .code p { 
  direction: rtl;
}
#ctn_loader{
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 3;
}
#loader{
    width: 100%;
    height: 100%;
    background: #ffffffa8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ctn_loader_show{
  display: unset !important;
}
.numpad_qte .popup_msg{
  position: absolute;
    top: 35px;
    left: 173px;
    width: 340px;
    padding: 5px;
    box-sizing: border-box;
    height: 40px;
    background: #B42318;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
}
.numpad_qte .popup_msg img{
  width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
}
.numpad_qte .popup_msg span{
  position: relative;
    top: -10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
}
.numpad_qte .MuiInput-root{
    margin: 20px 0 40px;
    display: block;
}
.numpad_qte .MuiInput-root::before{
  display: none; 
}
.numpad_qte .MuiInputBase-input{
  padding: 24px 20px;
  width: 312px;
  height: 83px;
  background: #FFFFFF;
  border: 2px solid #F18800;
  box-shadow: 0px 0px 16px rgb(241 136 0 / 25%);
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: #1D2939;
  margin: 0 auto;
}
.numpad_qte .MuiButton-fullWidth {
  padding: 24px;
    width: 413px;
    height: 81px; 
    border-radius: 100px;
    box-sizing: border-box;
    color: white !important;
    font-weight: 600;
    font-size: 26px;
    display: block;
    margin: 50px auto 0;
    border: none !important;
    background: #F18800;
}
.numpad_qte .MuiButton-fullWidth:hover { 
    background: #F18800;
}
.numpad_qte .Mui-disabled{
  background: #98A2B3 !important;
} 
.numpad_qte .MuiDialog-paperWidthSm { 
  width: 647px;
  height: 1060px;
  max-width: none;
  box-sizing: border-box;
  overflow: hidden;
  padding: 50px 0 0 0;
}
.ctn_article{
  padding: 60px 103px 70px;
    box-sizing: border-box;
    height: 215px;
    overflow: hidden;
    position: relative;
}
.ctn_article .promo{
  padding: 8px 12px;
    position: absolute;
    width: 55px;
    height: 32px;
    background: #FFC700;
    border-radius: 0px 2px 2px 0px;
    box-sizing: border-box;
    top: 60px;
    left: 0;
}
.ctn_article .promo span{
  font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #11314F;
}
.ctn_article .totale{
  position: absolute;
    bottom: 16px;
    right: 104px;
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.5px;
    color: #1D2939;
}
.ctn_article .ctn_img{
  float: left;
    background: #FFFFFF;
    border-radius: 2px;
    width: 84px;
    height: 84px;
}
.ctn_article .ctn_img img{
  width: 100%;
  height: 100%;
}
.ctn_article .ctn_inf{
  float: left;
  width: 350px;
  position: relative;
  top: 10px;
} 
.ctn_article .ctn_inf .ctn_price{
  overflow: hidden;
}
.ctn_article .ctn_inf .ctn_price span{
  display: block;
}
.ctn_article .ctn_inf .ctn_price span:first-child{
  float: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1D2939;
}
.numpad_qte_ar .ctn_article .ctn_inf .ctn_price span:first-child{
  direction: rtl;
}
.numpad_qte_ar .ctn_article .totale span {
  direction: rtl;
}
.numpad_qte_ar .ctn_article .totale div{
  direction: rtl;
}
.numpad_qte_ar .ctn_article .totale div span{
  margin: 0 5px;
}
.ctn_article .ctn_inf .ctn_price span:last-child{
  float: right;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-decoration-line: line-through;
    color: #1D2939;
}
.ctn_article .ctn_inf .ctn_desc h5{
  margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #1D2939;
}
.ctn_article .ctn_inf .ctn_info p{
  margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #667085;
}
.numpad_qte_ar .popup_msg { 
  direction: rtl;
}
.numpad_qte_ar .popup_msg img { 
  margin: 0 0 0 10px;
}
.screen_background_otp .popup_msg{
  position: absolute;
    bottom: 35px;
    left: 60px; 
    padding: 10px 20px;
    box-sizing: border-box;
    height: 50px;
    background: #B42318;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
}
.screen_background_otp .popup_msg img{
  width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
}
.screen_background_otp .popup_msg span{
  position: relative;
    top: -10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
}
.screen_background_otp_ar .popup_msg{
  direction: rtl;
}
.screen_background_otp_ar .popup_msg img {
  margin: 0 0 0 10px;
}
.popup_slots_ar .ctn_slots div p {
  float: right; 
}
.popup_slots_ar .ctn_slots div img {
  float: left; 
}
.popup_cgu .MuiDialog-paperWidthSm {
  max-width: none;
  max-height: none;
  width: 900px !important;
  height: 1400px;
}
.popup_cgu .cgu_ctn{
  padding: 90px 0 0;
    position: relative;
}
.popup_cgu .cgu_ctn .close_i{
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  top: 0;
}
.popup_cgu .cgu_ctn .close_i div{
  width: 100%;
  height: 100%;
}
.popup_cgu .cgu_ctn .close_i div img{
  width: 100%;
  height: 100%;
}
.popup_cgu .cgu_ctn .cgu p{
  text-align: left;
    margin: 10px 0;
    font-size: 30px;
    padding: 0 20px;
    font-weight: 600;
    font-family: serif;
    line-height: 40px;
    color: #000000;
}
.store_container_promotion .MuiDialog-paperWidthSm { 
  width: 859px !important;
  height: 1262px;
  max-width: none;
  position: relative;
  background: #FFFFFF;
  border: 8px solid #F18800;
  border-radius: 10px;
} 
.store_container_promotion .promotion_top{
  width: 859px;
  height: 102px; 
  background-color: white;
  text-align: center;
}
.store_container_promotion .promotion_top img{
  width: 281px;
  height: 69px;
  position: relative;
    top: 16px;
}
.store_container_promotion .articles_listes_catalog{
  height: 1159px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;    
  background-image:url("https://i.ibb.co/5nyQmwL/promo-background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  padding: 56px 66px;
  box-sizing: border-box;
}
.store_container_promotion .articles_listes_catalog::-webkit-scrollbar{
  display: none; /* for Chrome, Safari, and Opera */
}
.store_container_promotion .articles_listes_catalog .article .added_cart div img { 
  top: 5px;
  left: 1px;
}
.store_container_promotion .articles_listes_catalog .article .ctn_price span { 
  height: 28px;
}
.store_container_promotion .articles_listes_catalog .article .ctn_desc h5 { 
  line-height: 20px; 
}
.store_container_promotion .articles_listes_catalog .article .qte .ctn .plus img { 
  top: 5px;
}
.store_container_promotion .articles_listes_catalog .article .qte .ctn .minus img { 
  top: -3px;
} 
.store_container_promotion h3{
  font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #1D2939;
    margin: 32px 0 76px 0;
} 
.store_container_promotion .MuiDialogContent-root:first-child { 
    padding: 0;
    margin: 0;
}
.store_container_promotion .close{
  position: absolute;
    width: 32px;
    height: 32px;
    top: 32px;
    right: 32px;
}
.store_container_promotion .close img{
  width: 100%;
}
.store_container_promotion .articles_listes_catalog .article .promo span { 
  top: -15px; 
}
.store_container_promotion .articles_listes_catalog .article .ctn_price span:last-child { 
  line-height: unset;
}
.store_container_promotion .articles_listes_catalog .article { 
  background-color: white; 
}
.stock_dialog .MuiDialogActions-root {
  text-align: center;
}
.stock_dialog .MuiDialogContent-root { 
  margin: 50px auto 50px;
}
.stock_dialog p{
  font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1px; 
color: #11314F;
}
.store_container_promotion .articles_listes_catalog .article .ctn_desc { 
  min-height: 40px;
}
.articles_listes_catalog_ar .article .ctn_bottom_info>div { 
  float: right; 
  direction: rtl; 
}
.articles_listes_catalog_ar .article .ctn_bottom_info .left p { 
  text-align: right;
}
.articles_listes_catalog_ar .article .ctn_bottom_info .right { 
  text-align: left; 
}
.articles_listes_catalog .article .ctn_bottom_info_without_palier_ar .right {
  width: 100%;
  text-align: center;
} 
/*==========================================================*/
.popup_single_article .ctn_bottom_info{
  overflow: hidden;
    margin-top: 8px;
    width: 100%;
    height: 108px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #98A2B3;
    border-radius: 8px;
}
.popup_single_article .ctn_bottom_info>div{
  width: 50%;
    float: left;
    padding: 16px;
    box-sizing: border-box;
    height: 100%;
}
.popup_single_article .ctn_bottom_info div p{
  margin: 0;
  padding: 0;
  line-height: normal;
}
.popup_single_article .ctn_bottom_info .left{
  background-color: #E03636;
    color: white;
}
.popup_single_article .ctn_bottom_info .left p{
  text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
}
.popup_single_article .ctn_bottom_info .left p:first-child{
  font-weight: 400;
font-size: 16px;
line-height: 24px;  
letter-spacing: 0.5px;
}
.popup_single_article .ctn_bottom_info .left p:last-child{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; 
  letter-spacing: 0.5px;
}
.popup_single_article .ctn_bottom_info .right{
  background-color: #fff;
    color: #1D2939;
    text-align: right;  
    height: 100%;
    display: table;
}
.popup_single_article .ctn_bottom_info .right div{ 
  display: table-cell;
    vertical-align: middle;
}
.popup_single_article .ctn_bottom_info .right p{
  font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
}
.popup_single_article .ctn_bottom_info .right p span{
  color: white;
}
.popup_single_article .ctn_bottom_info .right p:first-child{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; 
  letter-spacing: 0.5px;
    text-decoration: line-through;
}
.popup_single_article .ctn_bottom_info .right .no_line_through{ 
    opacity: 0;
}
.popup_single_article .ctn_bottom_info .right p:last-child{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; 
  letter-spacing: 0.5px;
}
.popup_single_article .ctn_bottom_info_without_palier .right{
  width: 100%;
    text-align: center;
}
.popup_single_article_ar .ctn_bottom_info>div { 
  float: right; 
  direction: rtl; 
}
.popup_single_article_ar .ctn_bottom_info .left p { 
  text-align: right;
}
.popup_single_article_ar .ctn_bottom_info .right { 
  text-align: left; 
}
.popup_single_article_ar .ctn_bottom_info_without_palier_ar .right {
  width: 100%;
  text-align: center;
} 
.articles_listes_catalog .article .ctn_gift{
  padding: 4px 8px;
    position: absolute;
    width: 108px;
    height: 36px;
    left: 12px;
    top: 147px;
    background: #E00000;
    border-radius: 2px;
    overflow: hidden;
    box-sizing: border-box;
}
.articles_listes_catalog .article .ctn_gift div{
  float: left;
  color: white;
}
.articles_listes_catalog .article .ctn_gift div:first-child{
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.articles_listes_catalog .article .ctn_gift div:last-child{
  width: 59px;
    margin: 0 0 0 10px;
}
.articles_listes_catalog .article .ctn_gift div img{
  width: 100%;
  height: 100%;
}
.articles_listes_catalog .article .ctn_gift div p{
  font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 0;
} 
.articles_listes_catalog_ar .article .ctn_gift{
  direction: rtl;
} 
.articles_listes_catalog_ar .article .ctn_gift div:first-child{
  float: right;
}
.articles_listes_catalog_ar .article .ctn_gift div:last-child{
  margin: 0 10px 0 0;
} 
.store_container_promotion .articles_listes_catalog .article .ctn_gift div:first-child {
  top: -3px;
} 
.popup_single_article .ctn_gift{
  border: 1px solid #E03636;
    border-radius: 8px;
    height: 106px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    overflow: hidden;
    padding: 16px;
}
.popup_single_article .ctn_gift .gift{
  height: 100%;
    display: table;
    width: 100%;
}
.popup_single_article .ctn_gift .gift div{ 
  display: table-cell;
    vertical-align: middle;
}
.popup_single_article .ctn_gift .gift div p{
  margin: 0;
  padding: 0;
}
.popup_single_article .ctn_gift .bloc1{
  width: 86px;
}
.popup_single_article .ctn_gift .bloc1 p{
  font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #1D2939;
}
.popup_single_article .ctn_gift .bloc2{
  width: 150px;
  text-align: center;
}
.popup_single_article .ctn_gift .bloc2 p{
  font-size: 28px;
    font-weight: 700;
}
.popup_single_article .ctn_gift .bloc3{
  width: 190px;
} 
.popup_single_article .ctn_gift .bloc3 p:first-child{
  font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px; 
letter-spacing: 0.1px;  
color: #E03636;
}
.popup_single_article .ctn_gift .bloc3 p:last-child{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #667085;
}
.popup_single_article .ctn_gift .bloc4{
  width: 74px;
    height: 74px;
}
.popup_single_article .ctn_gift .bloc4 img{
  width: 100%;
}
.popup_single_article_ar .ctn_gift { 
  direction: rtl;
}
.popup_single_article  .ctn_bottom_info .left{ 
  height: 100%;
  display: table;
}
.popup_single_article .ctn_bottom_info .right div,.popup_single_article .ctn_bottom_info .left div{ 
display: table-cell;
  vertical-align: middle;
}
.page_panier_ar .ctn_orderlines .orderline .ctn_inf .gratuit p { 
  direction: rtl;
}
.ctn_catalogue_ar .dialog_msg .info_msg span, .dialog_msg .msg_min span { 
  direction: ltr;
}
.page_panier_ar .dialog_msg .info_msg span, .dialog_msg .msg_min span { 
  direction: ltr;
}
.catalogue_ctn_banners{ 
    height: 233px;
}
.catalogue_ctn_banners .ctn_img{}
.catalogue_ctn_banners .ctn_img .carousel .slide img {
  height: 233px; 
}
.catalogue_ctn_banners .ctn_img .carousel .control-dots {
  opacity: 0;
}
.catalogue_top{
  overflow: hidden;
    height: 400px;
    position: fixed;
}
.catalogue_bottom{ 
  overflow: hidden;
    padding-top: 400px;
}






